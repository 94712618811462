import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'no-result-found',
  templateUrl: './no-result-found.component.html',
  styleUrls: ['./no-result-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NoResultComponent {
  constructor(private translate: TranslateService) {}

  @Input() noResultsMessage: string = this.translate.instant(
    'common.no_result_found',
  );
}
