<div
  class="flex flex-row bg-white border border-gray-200 border-solid rounded-lg t-reser-details"
  *ngIf="priceInfo"
>
  <p
    class="p-4 whitespace-nowrap text-base font-semibold text-gray-900 w-[156px] xl:w-[204px]"
  >
    {{ prefixTranslation + "title" | translate }}
  </p>
  <div class="p-4 flex flex-col gap-3 text-md font-medium w-[calc(100%-204px)]">
    <span class="flex flex-row gap-2" *ngIf="!isHideTaxes">
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ prefixTranslation + "subtotal" | translate }}
      </p>
      <p class="text-gray-900 w-[calc(100%-177px)]">
        <!-- {{ "USD" | currencySymbol }}{{ priceInfo.subTotal | number: "1.2-2" }} -->
        {{
          priceInfo.subTotal
            | historyCurrency: userExchangeRate : userCurrency : adminCurrency
        }}
      </p>
    </span>
    <span class="flex flex-row gap-2" *ngIf="!isHideTaxes">
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ prefixTranslation + "taxes-charges" | translate }}
      </p>
      <p class="text-gray-900 w-[calc(100%-177px)]">
        <!-- {{ "USD" | currencySymbol
        }}{{ priceInfo.taxeAndCharge | number: "1.2-2" }} -->
        {{
          priceInfo.taxeAndCharge
            | historyCurrency: userExchangeRate : userCurrency : adminCurrency
        }}
      </p>
    </span>
    <!-- <div *ngIf="vouchersApplied && vouchersApplied.length > 0">
      <div *ngFor="let voucher of vouchersApplied">
        <span class="flex flex-row gap-2">
          <p class="text-gray-500 w-[152px] xl:w-[177px]">{{ voucher.name }}</p>
          <p class="text-gray-900 w-[calc(100%-152px)]">
            -{{ "USD" | currencySymbol }}{{ voucher.amount | number: "1.2-2" }}
          </p>
        </span>
      </div>
    </div> -->

    <!-- <span class="flex flex-row gap-2">
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ coinsApplied?.total_coins }}
        {{ coinsApplied > 1 ? "coins" : "coin" }}
      </p>
      <p class="text-gray-900 w-[calc(100%-152px)]">
        -{{ "USD" | currencySymbol }}
        {{ coinsApplied.amount | number: "1.2-2" }}
      </p>
    </span> -->
    <span class="flex flex-row gap-2">
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ prefixTranslation + "adult" | translate | sentenceCase }} (x{{
          priceInfo.totalAdult ? priceInfo.totalAdult : 0
        }})
      </p>
      <p class="text-gray-900 w-[calc(100%-177px)] whitespace-nowrap">
        <!-- {{ "USD" | currencySymbol }}{{ priceInfo.adultPrice | number: "1.2-2" }} -->
        {{
          priceInfo.adultPrice
            | historyCurrency: userExchangeRate : userCurrency : adminCurrency
        }}
      </p>
    </span>
    <span
      class="flex flex-row gap-2"
      *ngIf="
        priceInfo.totalChild > 0 ||
        (priceInfo.ageChild ?? 0) > 0 ||
        (priceInfo.heightChild ?? 0) > 0
      "
    >
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ prefixTranslation + "child" | translate | sentenceCase }} (x{{
          priceInfo.totalChild ? priceInfo.totalChild : 0
        }})
      </p>
      <p class="text-gray-900 w-[calc(100%-177px)] whitespace-nowrap">
        <!-- {{ "USD" | currencySymbol }}{{ priceInfo.childPrice | number: "1.2-2" }} -->
        {{
          priceInfo.childPrice
            | historyCurrency: userExchangeRate : userCurrency : adminCurrency
        }}
      </p>
    </span>
    <span
      class="flex flex-row gap-2"
      *ngIf="(priceInfo.totalInfant ?? 0) > 0 || (priceInfo.ageInfant ?? 0) > 0"
    >
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ prefixTranslation + "infant" | translate | sentenceCase }} (x{{
          priceInfo.totalInfant ? priceInfo.totalInfant : 0
        }})
      </p>
      <p class="text-gray-900 w-[calc(100%-177px)] whitespace-nowrap">
        <!-- {{ "USD" | currencySymbol
        }}{{ priceInfo.infantPrice | number: "1.2-2" }} -->
        {{
          priceInfo.infantPrice
            | historyCurrency: userExchangeRate : userCurrency : adminCurrency
        }}
      </p>
    </span>

    <div *ngFor="let package of priceInfo?.package_options || []">
      <div class="flex flex-row gap-2 w-full">
        <div class="text-gray-500 w-[152px] xl:w-[177px]">
          <div
            class="flex items-start gap-2 cursor-pointer"
            (click)="package.isExpand = !package.isExpand"
          >
            <span class="text-md text-gray-500">
              {{ package?.name }}
            </span>
            <i
              *ngIf="!package.isExpand"
              class="text-gray-700 sctr-icon-chevron-down text-md mt-[0.25rem]"
            ></i>
            <i
              *ngIf="package.isExpand"
              class="text-gray-700 sctr-icon-chevron-up text-md mt-[0.25rem]"
            ></i>
          </div>
        </div>
        <div
          class="font-medium text-gray-900 text-md whitespace-nowrap w-[calc(100%-177px)]"
        >
          <!-- <span>
            {{ "USD" | currencySymbol
            }}{{ package?.price || 0 | number: "1.2-2" }}
          </span> -->
          <span>
            {{
              package?.price || 0
                | historyCurrency
                  : userExchangeRate
                  : userCurrency
                  : adminCurrency
            }}
          </span>
        </div>
      </div>

      <div
        *ngIf="package.isExpand"
        class="flex flex-col gap-2 pl-3 mt-2 w-full"
        [@slideDownUp]
      >
        <div class="flex items-center flex-row gap-2">
          <p class="font-normal text-gray-500 text-md xl:w-[165px] w-[140px]">
            {{ prefixTranslation + "adult" | translate | sentenceCase }} (x{{
              package?.travellers?.total_adults || 0
            }})
          </p>
          <div
            class="font-medium text-gray-500 text-md whitespace-nowrap w-[calc(100%-165px)]"
          >
            <!-- <span>
              {{ "USD" | currencySymbol
              }}{{
                roundNumberService.roundNumber(
                  package?.travellers?.total_price_adults || 0
                ) | number: "1.2-2"
              }}
            </span> -->
            <span>
              {{
                package?.travellers?.total_price_adults || 0
                  | historyCurrency
                    : userExchangeRate
                    : userCurrency
                    : adminCurrency
              }}
            </span>
          </div>
        </div>
        <div
          class="flex items-center flex-row gap-2"
          *ngIf="priceInfo.totalChild > 0"
        >
          <p class="font-normal text-gray-500 text-md xl:w-[165px] w-[140px]">
            {{ prefixTranslation + "child" | translate | sentenceCase }} (x{{
              package?.travellers?.total_children || 0
            }})
          </p>
          <div
            class="font-medium text-gray-500 text-md whitespace-nowrap w-[calc(100%-165px)]"
          >
            <!-- <span>
              {{ "USD" | currencySymbol
              }}{{
                package?.travellers?.total_price_children || 0 | number: "1.2-2"
              }}
            </span> -->
            <span>
              {{
                package?.travellers?.total_price_children || 0
                  | historyCurrency
                    : userExchangeRate
                    : userCurrency
                    : adminCurrency
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="priceInfo?.promotions?.length">
      <div class="text-gray-500 w-[152px] xl:w-[177px] mb-[-0.25rem]">
        {{ "booking-helpdesk.promotion" | translate }}
      </div>
      <div *ngFor="let promotion of priceInfo?.promotions">
        <div
          *ngIf="promotion.total_discount?.total! > 0"
          class="flex items-center gap-2"
        >
          <div class="w-[152px] xl:w-[177px]">
            <div
              class="flex items-center gap-1 bg-success-50 !pr-2 !pl-1.5 !py-1 rounded-2xl w-fit max-w-full"
            >
              <img
                class="w-3 h-3"
                src="assets/icons/voucher-payment/checked-voucher.svg"
                alt=""
              />
              <span
                class="text-sm font-medium truncate text-success-600"
                pTooltip="{{ promotion.name }} ({{
                  roundNumberService.roundNumber(
                    promotion.total_discount?.percent
                  )
                }}%)"
                tooltipPosition="top"
                tooltipStyleClass="t-tooltip-light !w-max !max-w-full"
              >
                {{ promotion.name }}
              </span>
            </div>
          </div>
          <p class="text-gray-900 w-[calc(100%-177px)]">
            <!-- -{{ "USD" | currencySymbol
            }}{{
              roundNumberService.roundNumber(promotion.total_discount?.total)
                | number: "1.2-2"
            }} -->
            {{
              promotion.total_discount?.total
                | historyCurrency
                  : userExchangeRate
                  : userCurrency
                  : adminCurrency
            }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        priceInfo?.membershipDiscount &&
        priceInfo?.membershipDiscount?.total > 0
      "
    >
      <div class="text-gray-500 w-[152px] xl:w-[177px] mb-[-0.25rem]">
        {{ "booking-helpdesk.membership" | translate }}
      </div>
      <div class="flex items-center gap-2">
        <div class="w-[152px] xl:w-[177px]">
          <div
            class="flex items-center gap-1 bg-success-50 !pr-2 !pl-1.5 !py-1 rounded-2xl w-fit max-w-full"
          >
            <img
              class="w-3 h-3"
              src="assets/icons/voucher-payment/checked-voucher.svg"
              alt=""
            />
            <span
              class="text-sm font-medium truncate text-success-600"
              pTooltip="{{ prefixTranslation + 'membership' | translate }}"
              tooltipPosition="top"
              tooltipStyleClass="t-tooltip-light !w-max !max-w-full"
            >
              {{ prefixTranslation + "membership" | translate }}
              {{
                priceInfo.membershipDiscount?.percent > 0
                  ? "(" +
                    (priceInfo.membershipDiscount?.percent * 100).toFixed(0) +
                    "%)"
                  : ""
              }}
            </span>
          </div>
        </div>
        <p class="text-gray-900 w-[calc(100%-177px)]">
          <!-- -{{ "USD" | currencySymbol
          }}{{
            roundNumberService.roundNumber(priceInfo.membershipDiscount?.total)
              | number: "1.2-2"
          }} -->
          {{
            priceInfo.membershipDiscount?.total
              | historyCurrency: userExchangeRate : userCurrency : adminCurrency
          }}
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="priceInfo?.vouchers?.length">
      <div class="text-gray-500 w-[152px] xl:w-[177px] mb-[-0.25rem]">
        {{ "booking-helpdesk.voucher" | translate }}
      </div>
      <div *ngFor="let voucher of priceInfo?.vouchers">
        <div
          *ngIf="voucher?.discount_price! > 0"
          class="flex items-center gap-2"
        >
          <div class="w-[152px] xl:w-[177px]">
            <div
              class="flex items-center gap-1 bg-success-50 !pr-2 !pl-1.5 !py-1 rounded-2xl w-fit max-w-full"
            >
              <img
                class="w-3 h-3"
                src="assets/icons/voucher-payment/checked-voucher.svg"
                alt=""
              />
              <span
                class="text-sm font-medium truncate text-success-600"
                pTooltip="{{ voucher.name }}"
                tooltipPosition="top"
                tooltipStyleClass="t-tooltip-light !w-max !max-w-full"
              >
                {{ voucher.name }}
              </span>
            </div>
          </div>
          <p class="text-gray-900 w-[calc(100%-177px)]">
            <!-- -{{ "USD" | currencySymbol
            }}{{
              roundNumberService.roundNumberDown(voucher?.discount_price)
                | number: "1.2-2"
            }} -->
            {{
              voucher?.discount_price
                | historyCurrency
                  : userExchangeRate
                  : userCurrency
                  : adminCurrency
            }}
          </p>
        </div>
      </div>
    </ng-container>
    <span
      class="flex flex-row gap-2 pt-3 border-t border-gray-200 border-dashed"
    >
      <p class="text-gray-500 w-[152px] xl:w-[177px]">
        {{ prefixTranslation + "total" | translate }}
      </p>
      <p class="text-lg font-semibold text-gray-900 w-[calc(100%-177px)]">
        <!-- {{ "USD" | currencySymbol
        }}{{ priceInfo.totalPrice || 0 | number: "1.2-2" }} -->
        {{
          priceInfo.totalPrice || 0
            | historyCurrency: userExchangeRate : userCurrency : adminCurrency
        }}
      </p>
    </span>
    <span *ngIf="discountData" class="flex flex-row gap-2">
      <discount-detail-lib
        [environment]="environment"
        [orderId]="discountData.orderId"
        [language]="discountData.userLanguage"
        [userCurrency]="discountData.userCurrency"
        [token]="discountData.userToken"
        [userRole]="discountData.userRole"
      ></discount-detail-lib>
    </span>
  </div>
</div>
