<div class="t-blog-section grid bg-white rounded-lg p-4 gap-4 mt-3">
  <div class="grid">
    <h4 class="text-gray-900 font-semibold text-xl">Adventure styles</h4>
    <p class="text-[14px] text-gray-700">
      {{ prefix + "quantity-of-items" | translate }}
    </p>
    <p
      *ngIf="
        adventureStylesSection && adventureStylesSection?.items?.length! >= 20
      "
      class="text-[14px] text-warning-500"
    >
      {{ prefix + "maximum-quantity" | translate }}
    </p>
  </div>

  <div class="grid gap-2">
    <span class="text-gray-900 font-medium text-[14px] flex gap-1"
      >{{ prefix + "heading" | translate }}
      <p class="text-error-500">*</p>
      <p class="text-gray-400">({{ heading.value?.length || 0 }}/30)</p></span
    >
    <div class="flex gap-2">
      <button
        *ngFor="let lang of listLangsHeading"
        [ngClass]="
          lang === activeLangHeading
            ? 'bg-blue-500 text-white border-blue-500'
            : 'bg-white text-gray-500 border-gray-500'
        "
        class="text-sm w-7 rounded-md border"
        (click)="changeLangHeading(lang)"
      >
        {{ lang }}
      </button>
    </div>
    <input
      class="w-full border border-gray-300 rounded-md p-2"
      type="text"
      placeholder="{{ prefix + 'enter-heading' | translate }}"
      [formControl]="heading"
      (input)="inputHeading()"
      maxlength="30"
      required
    />
    <span
      *ngIf="
        !checkKeys(adventureStylesSection?.heading ?? {}, listLangsHeading) &&
        heading.touched
      "
      class="text-error-500 text-sm"
    >
      {{ prefix + "heading-is-required" | translate }}.{{
        prefix + "some-lang-missing" | translate
      }}
    </span>
  </div>
  <div class="t-description grid gap-2">
    <span class="text-gray-900 font-medium text-[14px] flex gap-1">
      {{ prefix + "description" | translate }}
      <p class="text-error-500">*</p>
      <p class="text-gray-400">({{ content.value?.length || 0 }}/400)</p></span
    >
    <div class="flex gap-2">
      <button
        *ngFor="let lang of listLangsContent"
        [ngClass]="
          lang === activeLangContent
            ? 'bg-blue-500 text-white border-blue-500'
            : 'bg-white text-gray-500 border-gray-500'
        "
        class="text-sm w-7 rounded-md border"
        (click)="changeLangContent(lang)"
      >
        {{ lang }}
      </button>
    </div>
    <textarea
      class="w-full border border-gray-300 rounded-md p-2"
      placeholder="{{ prefix + 'enter-description' | translate }}"
      rows="4"
      [formControl]="content"
      (input)="inputContent()"
      maxlength="400"
    ></textarea>
    <span
      *ngIf="
        !checkKeys(adventureStylesSection?.content ?? {}, listLangsContent) &&
        content.touched
      "
      class="text-error-500 text-sm"
    >
      {{ prefix + "heading-is-required" | translate }}.{{
        prefix + "some-lang-missing" | translate
      }}
    </span>
  </div>
  <div class="grid gap-2">
    <div class="flex justify-between">
      <span class="text-gray-900 font-medium text-[14px] flex gap-1"
        >Adventure styles management
        <p class="text-error-500">*</p>
        <p class="text-gray-400">
          ({{ adventureStylesSection?.items?.length || 0 }}/20)
        </p>
      </span>
      <button
        class="bg-blue-600 rounded-lg text-white p-2 text-[14px] align-middle content-center flex gap-1"
        [ngClass]="
          adventureStylesSection?.items?.length! >= 20
            ? 'bg-gray-300'
            : 'bg-blue-600 hover:bg-blue-500'
        "
        (click)="isShowAddAdventureModal = true"
        [disabled]="adventureStylesSection?.items?.length! >= 20"
      >
        <i class="sctr-icon-plus-circle pt-1"></i>
        {{ prefix + "add-item" | translate }}
      </button>
    </div>
    <div
      cdkDropList
      class="grid rounded-xl"
      (cdkDropListDropped)="drop($event)"
    >
      <div
        class="gird gap-3 p-1 rounded-xl"
        *ngFor="
          let adventureStyle of adventureStylesSection?.items;
          let i = index
        "
        cdkDrag
      >
        <div class="flex h-[96px] gap-2 rounded-xl">
          <i class="sctr-icon-dots-grid"></i>
          <div class="flex gap-1 bg-gray-200 rounded-xl border p-2">
            <div class="relative inline-block">
              <img
                class="w-[160px] h-[80px] rounded-xl object-cover"
                [src]="adventureStyle.image?.id! | image"
                alt="adventure style"
              />
              <!-- <div class="flex gap-1 justify-between">
                <input
                  id="{{ adventureStyle.id }}"
                  type="file"
                  (change)="onFileReplace($event, adventureStyle)"
                  accept=".jpg, .jpeg, .png"
                  hidden
                />
                <label
                  for="{{ adventureStyle.id }}"
                  class="sctr-icon-upload-cloud-02 text-white text-[24px] font-medium absolute top-0 right-0 m-1 cursor-pointer"
                >
                </label>
              </div> -->
            </div>
            <textarea
              class="w-[700px] h-[80px] border border-gray-300 rounded-md p-2"
              placeholder="Enter description"
              rows="4"
              [value]="adventureStyle.title"
            ></textarea>
          </div>
          <div class="flex gap-3">
            <p-inputSwitch
              [(ngModel)]="adventureStyle.is_active"
              class="flex"
            ></p-inputSwitch>
            <button
              (click)="removeAdventureStyle(i, adventureStyle.id ?? '')"
              class="sctr-icon-trash-01 flex text-[20px] text-gray-500"
            ></button>
          </div>
          <div class="custom-placeholder" *cdkDragPlaceholder></div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal
  [showDialog]="isShowAddAdventureModal"
  (emitOnCloseDialog)="closeAdventureForm()"
  [dialogConfig]="addAdventureModalConfig"
  [showFooterDialog]="false"
>
  <div modal-header class="font-Inter flex flex-col gap-1">
    <h5 class="text-lg font-semibold text-gray-900">
      {{ prefix + "add-adventure-style" | translate }}
    </h5>
  </div>
  <div modal-content class="font-Inter flex flex-col gap-2">
    <p-table
      #dt
      dataKey="id"
      [value]="listAdventure"
      [rowHover]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [paginator]="true"
      styleClass="text-md/none overflow-x-auto"
      class="t-table-adventure"
      [globalFilterFields]="globalFilterFields"
      [(selection)]="selectedAdventures"
    >
      <ng-template pTemplate="caption">
        <div class="flex bg-white">
          <span class="p-input-icon-left bg-white px-0">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="onSearch($event, dt)"
              [placeholder]="
                'adventure-style-management.search-by-name' | translate
              "
              class="t-search-input w-full h-10 font-Inter"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="whitespace-nowrap">
            {{ prefix + "adventure-styles" | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-adventure>
        <tr>
          <td>
            <p-tableCheckbox [value]="adventure"></p-tableCheckbox>
          </td>
          <td>
            <div class="flex flex-row items-center gap-3">
              <img
                class="w-[32px] h-[32px] rounded object-cover"
                [src]="
                  adventure.images[0].id ? (adventure.images[0].id | image) : ''
                "
                alt="adventure style"
              />
              <p class="whitespace-nowrap" [pTooltip]="adventure.name">
                {{ adventure.name }}
              </p>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="9">
            <no-result-found></no-result-found>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div modal-footer class="justify-end flex gap-2">
    <button
      class="bg-white rounded-lg text-gray900 w-24 hover:bg-gray-100 p-2 text-[14px] align-middle content-center justify-center flex gap-1 border border-gray-400"
      (click)="closeAdventureForm()"
    >
      {{ prefix + "cancel" | translate }}
    </button>
    <button
      class="bg-blue-600 hover:bg-blue-500 rounded-lg text-white p-2 text-[14px] align-middle content-center flex gap-1 w-20 justify-center items-center"
      (click)="addAdventure()"
      [ngClass]="
        selectedAdventures.length == 0
          ? 'bg-gray-300'
          : 'bg-blue-600 hover:bg-blue-500'
      "
      [disabled]="selectedAdventures.length === 0"
    >
      {{ prefix + "save" | translate }}
    </button>
  </div>
</app-modal>
