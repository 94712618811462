import { MessageService } from 'primeng/api';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  LandingPageMngtControllerService,
  LPSectionItemDTO,
} from '@assistant/angular-travel-admin-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { CustomTranslateService } from '@core/services/custom-translate.service';
import { LPSectionAdminViewDTO } from '@assistant/angular-travel-admin-service/model/lPSectionAdminViewDTO';
import { LPSectionUpdateV2DTO } from '@assistant/angular-travel-admin-service/model/lPSectionUpdateV2DTO';
@Component({
  selector: 'app-landing-page-content',
  templateUrl: './landing-page-content.component.html',
  styleUrls: ['./landing-page-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageContentComponent implements OnInit {
  countries: any;
  selectedCountry: any;
  selectLang: any;
  prefix: string = 'landing-page-content.';
  constructor(
    private landingPageService: LandingPageMngtControllerService,
    private messageService: MessageService,
    private http: HttpClient,
    private translator: CustomTranslateService,
  ) {}

  landingPageContent:
    | {
        [key: string]: LPSectionAdminViewDTO;
      }
    | undefined = {};

  heroSection: LPSectionAdminViewDTO | undefined;
  blogSection: LPSectionAdminViewDTO | undefined;
  adventureStylesSection: LPSectionAdminViewDTO | undefined;
  explorePlacesSection: LPSectionAdminViewDTO | undefined;
  labelSection: LPSectionAdminViewDTO | undefined;

  ngOnInit() {
    this.fetchCountryListAndGetAllLPSection();
  }

  private getAllLPSection() {
    this.landingPageService
      .getAllLPSectionV2(
        this.selectedCountry.country_code.toLowerCase(),
        // this.selectedCountry.country_code.toLowerCase(),
      )
      .subscribe({
        next: (res) => {
          this.landingPageContent = res.data;
          this.heroSection = res.data ? res.data['Hero'] : undefined;
          this.labelSection = res.data ? res.data['TourLabel'] : undefined;
          this.blogSection = res.data ? res.data['Introduction'] : undefined;
          this.adventureStylesSection = res.data
            ? res.data['AdventureStyle']
            : undefined;
          this.explorePlacesSection = res.data
            ? res.data['ExplorePlace']
            : undefined;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  save() {
    const body: LPSectionUpdateV2DTO[] = [
      this.heroSection,
      this.labelSection,
      this.blogSection,
      this.adventureStylesSection,
      this.explorePlacesSection,
    ].filter((section) => section !== undefined) as LPSectionUpdateV2DTO[];
    this.messageService.add({
      severity: 'success',
      summary: this.translator.transform(this.prefix + 'saving'),
      detail: this.translator.transform(this.prefix + 'saving-data'),
      icon: 'sctr-icon-refresh-ccw-03 animate-spin',
      life: 100000,
    });
    this.landingPageService.updateLPSectionListV2(body).subscribe({
      next: (res) => {
        if (res.success) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: this.translator.transform(this.prefix + 'success'),
            detail: this.translator.transform(
              this.prefix + 'data-saved-successfully',
            ),
          });
        }
      },
      error: (err) => {
        console.error(err);
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: this.translator.transform(this.prefix + 'error'),
          detail: this.translator.transform(
            this.prefix + 'an-error-occurred-please-try-again-later',
          ),
        });
      },
    });
  }

  preview() {
    throw new Error('Method not implemented.');
  }

  changeLanguage($event: any) {
    this.getAllLPSection();
  }

  fetchCountryListAndGetAllLPSection() {
    const params = new HttpParams().set('validCountry', true);
    this.http
      .get(`${environment.BASE_API_URL}/regional/regional/country`, {
        params,
      })

      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.countries = res.data;
            this.selectedCountry = res.data[1];
            this.selectLang = res.data[1];
            this.getAllLPSection();
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  invalidHeroSection() {
    const listHeadingLangs: string[] = Object.keys(
      this.heroSection?.heading ?? {},
    );
    for (let lang of listHeadingLangs) {
      if (this.heroSection?.heading && !this.heroSection?.heading[lang]) {
        return true;
      }
    }
    if (this.heroSection?.items?.length === 0) {
      return true;
    }
    return false;
  }

  invalidBlogMedia() {
    const listHeadingLangs: string[] = Object.keys(
      this.blogSection?.heading ?? {},
    );
    for (let lang of listHeadingLangs) {
      if (this.blogSection?.heading && !this.blogSection?.heading[lang]) {
        return true;
      }
    }
    if (this.blogSection?.items?.length === 0) {
      return true;
    }
    const listContentLangs: string[] = Object.keys(
      this.blogSection?.content ?? {},
    );
    for (let lang of listContentLangs) {
      if (this.blogSection?.content && !this.blogSection?.content[lang]) {
        return true;
      }
    }
    return false;
  }

  invalidAdventureStyles() {
    const listHeadingLangs: string[] = Object.keys(
      this.adventureStylesSection?.heading ?? {},
    );
    for (let lang of listHeadingLangs) {
      if (
        this.adventureStylesSection?.heading &&
        !this.adventureStylesSection?.heading[lang]
      ) {
        return true;
      }
    }
    if (this.adventureStylesSection?.items?.length === 0) {
      return true;
    }
    const listContentLangs: string[] = Object.keys(
      this.adventureStylesSection?.content ?? {},
    );
    for (let lang of listContentLangs) {
      if (
        this.adventureStylesSection?.content &&
        !this.adventureStylesSection?.content[lang]
      ) {
        return true;
      }
    }
    return false;
  }

  disable() {
    return this.invalidBlogMedia() || this.invalidAdventureStyles();
  }

  scrollToBottom(): void {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }
}
