import { Component, Input, inject } from '@angular/core';
import { RoundNumberService } from '@core/services/round-number.service';
import { environment } from '@env/environment';
import { slideDownUp } from '@shared/animations/slide-down-up.animation';
export interface IDiscountData {
  userLanguage: string;
  userCurrency: string;
  userToken: string | undefined;
  userRole: string;
  orderId: string;
}
export interface PriceDetails {
  subTotal: number;
  taxeAndCharge: number;
  vouchers: any[];
  coinsApplied: any;
  totalPrice: number;
  adultPrice: number;
  childPrice: number;
  totalAdult: number;
  totalChild: number;
  ageChild?: number;
  heightChild?: number;
  promotions?: any[];
  membershipDiscount?: any;
  package_options?: any[];
  totalInfant?: number;
  ageInfant?: number;
  infantPrice?: number;
}
@Component({
  selector: 'app-price-details',
  templateUrl: './price-details.component.html',
  styleUrls: ['./price-details.component.scss'],
  animations: [slideDownUp],
})
export class PriceDetailsComponent {
  public roundNumberService = inject(RoundNumberService);
  @Input() priceInfo: PriceDetails;
  @Input() userCurrency: string = 'USD';
  @Input() userExchangeRate: number = 1;
  @Input() adminCurrency: string = 'USD';
  @Input() discountData: IDiscountData;
  isHideTaxes = true;
  prefixTranslation =
    'section-booking-helpdesk.booking-helpdesk-detail.price-details.';
  environment = environment;
}
