<div
  class="t-booking-information bg-white min-h-[256px] flex flex-col gap-4 p-4 rounded-lg border border-solid border-gray-200 shadow-xs"
>
  <div class="t-infor-header flex flex-row justify-between">
    <h5 class="text-base font-semibold text-gray-900">
      {{ prefixTranslation + "title" | translate }}
    </h5>
    <p
      class="t-reservation-state t-reservation-state-{{
        getClassStatus(reservationStatus.trim())
      }}"
    >
      {{
        capitalizeFirstLetter(reservationStatus.replace("_", " ").toLowerCase())
      }}
    </p>
  </div>
  <div
    class="w-full flex flex-col gap-2 rounded h-[100px] bg-blue-900 p-4 text-white"
  >
    <div class="flex flex-row justify-between">
      <div class="flex items-center">
        <span class="text-md font-medium">{{ reservationCode }}</span>
        <button
          class="w-6 h-6 ml-1 flex items-center justify-center"
          (click)="onCopy(reservationCode)"
          [pTooltip]="'common.copied' | translate | sentenceCase"
          [life]="500"
          tooltipEvent="focus"
        >
          <span class="sctr-icon-copy-06 text-lg t-blue--600"></span>
        </button>
      </div>
      <div class="flex flex-row gap-2 items-center">
        <button class="t-tour-btn" (click)="handlePrint()">
          <i class="sctr-icon-printer1 t-tour-btn-icon"> </i>
        </button>
        <button class="t-tour-btn" (click)="handleInItQRCode()">
          <i class="sctr-icon-qr-code-02 t-tour-btn-icon"> </i>
        </button>
      </div>
    </div>
    <p class="text-2xl font-semibold">
      <!-- {{ "USD" | currencySymbol }}{{ reservationNetlPrice | number: "1.2-2" }} -->
      {{
        reservationNetlPrice
          | historyCurrency: userExchangeRate : userCurrency : adminCurrency
      }}
    </p>
  </div>
  <div class="bg-gray-100 rounded-lg p-4 flex flex-col gap-1">
    <span class="flex flex-row gap-2 items-center">
      <p class="text-base font-semibold text-gray-900 min-w-max">
        <!-- {{ "USD" | currencySymbol }}
        {{ reservationCommissPrice | number: "1.2-2" }} -->
        {{
          reservationCommissPrice
            | historyCurrency: userExchangeRate : userCurrency : adminCurrency
        }}
      </p>
      <p class="text-sm font-normal text-gray-700">
        {{ prefixTranslation + "earning-message" | translate }}
      </p>
    </span>
    <p class="text-sm font-normal text-gray-500">
      ({{ reservationCommissPercent }}% {{ pageTitle }}
      {{ prefixTranslation + "commission" | translate }})
    </p>
  </div>
</div>

<app-modal
  [showDialog]="isShowQrCode"
  [dialogConfig]="dialogConfig"
  (emitOnCloseDialog)="isShowQrCode = false"
>
  <div modal-header class="flex gap-2 items-center">
    <span class="text-md font-normal text-gray-500">
      {{ "section-booking-helpdesk.booking-id" | translate }}:
    </span>
    <span class="text-xl font-semibold text-gray-900">
      {{ reservationCode }}
    </span>
  </div>
  <div modal-content>
    <ng-container *ngIf="isLoadImage">
      <img
        class="text-md font-normal text-gray-700 break-all"
        [src]="qrImageUrl"
        [alt]="qrCode"
      />
    </ng-container>
    <ng-template #falseTemplate>
      <span class="text-md font-normal text-error-600 text-nowrap">
        {{ "common.message-error.api-error-message" | translate }}
      </span>
    </ng-template>
  </div>
  <div modal-footer class="flex gap-3 items-center">
    <button (click)="onSaveQRCode()">
      <i class="sctr-icon-download-01 text-gray-700"></i>
      <span class="capitalize">{{ "section-action.save" | translate }}</span>
    </button>
    <button (click)="onCopyQRCode()">
      <div class="flex gap-1 items-center">
        <i class="sctr-icon-copy-06 text-gray-700"></i>
        <span>
          {{ "section-action.copy" | translate | sentenceCase }}
          {{ "common.link" | translate }}
        </span>
      </div>
    </button>
  </div>
</app-modal>
