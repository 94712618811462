<div class="t-landing-page-content grid justify-center gap-3">
  <button
    (click)="scrollToBottom()"
    class="fixed bottom-[3.75rem] xl:bottom-[2.75rem] right-[1.5rem]"
  >
    <i
      class="bg-white cursor-pointer p-2 pi pi-chevron-down rounded-full border border-gray-400 shadow text-gray-400 translate-x-1/2 translate-y-full z-10"
    ></i>
  </button>

  <div class="header flex justify-between content-center">
    <h3 class="text-lg font-semibold text-gray-900 text-center p-2">
      {{ prefix + "landing-page-content" | translate }}
    </h3>
    <div class="flex gap-3">
      <!-- <p-dropdown
        [options]="countries"
        [(ngModel)]="selectLang"
        (ngModelChange)="changeLanguage($event)"
        optionLabel="country"
        showEmptyMessage="true"
        emptyMessage="No results found"
        [style]="{ width: '200px' }"
      >
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectLang">
            <span class="text-gray-900 font-medium">
              {{ prefix + "language" | translate }}:</span
            >
            <div>{{ selectLang.country }}</div>
          </div>
        </ng-template>
      </p-dropdown> -->
      <p-dropdown
        [options]="countries"
        [(ngModel)]="selectedCountry"
        (ngModelChange)="changeLanguage($event)"
        optionLabel="country"
        showEmptyMessage="true"
        emptyMessage="No results found"
        [style]="{ width: '200px' }"
      >
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
            <span class="text-gray-900 font-medium">
              {{ prefix + "region" | translate }}:</span
            >
            <div>{{ selectedCountry.country }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="w-[1000px] items-center justify-center gap-3">
    <app-hero-section [heroSection]="heroSection"></app-hero-section>
    <app-label-section [labelSection]="labelSection"></app-label-section>
    <app-blog-section [blogSection]="blogSection"> </app-blog-section>
    <app-adventure-styles
      [adventureStylesSection]="adventureStylesSection"
    ></app-adventure-styles>
    <app-explore-places
      [explorePlacesSection]="explorePlacesSection"
    ></app-explore-places>
  </div>
  <div class="footer flex justify-end gap-3 px-4">
    <!-- <button
      (click)="preview()"
      class="bg-white rounded-lg text-gray900 w-24 hover:bg-gray-100 p-2 text-[14px] align-middle content-center justify-center flex gap-1 border border-gray-400"
    >
      {{ prefix + "preview" | translate }}
    </button> -->
    <button
      (click)="save()"
      class="rounded-lg w-20 text-white p-2 text-[14px] flex items-center justify-center gap-1"
      [ngClass]="
        disable()
          ? 'cursor-not-allowed bg-gray-300'
          : 'cursor-pointer bg-blue-600 hover:bg-blue-500'
      "
      [disabled]="disable()"
    >
      {{ prefix + "save" | translate }}
    </button>
  </div>
</div>
