export enum PAYMENT_EXTRA_TYPE {
  LOYALTY = 'LOYALTY',
}

export enum LOYALTY_MODULE {
  TOUR = 'TOUR',
}

export enum LOYALTY_USER_ROLE {
  USER = 'USER',
  AGENCY = 'AGENCY',
  ADMIN = 'ADMIN',
}
