import { MenuSidebar } from 'src/app/core/constants/navigation.config';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as _ from 'lodash';
import { ShopControllerService } from '@soctrip/angular-shop-service';
import { environment } from '@env/environment';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { NavigationItem } from 'src/app/core/models/interfaces/navigation';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  providers: [MessageService],
})
export class SidebarComponent implements OnInit {
  dialItems: any[];
  MenuSidebar = MenuSidebar;
  isMenuShow: boolean = true;
  showChildren: boolean[] = [];

  @Input() sidebarVisible = false;
  @Output() sidebarVisibleChange = new EventEmitter<boolean>();
  protected readonly SoctripIcons = SoctripIcons;

  constructor(private router: Router) {}

  ngOnInit() {
    this.setupSideBar();
    this.showChildren = this.MenuSidebar.map((item) => !!item.children);
  }

  setupSideBar() {
    // if (!this.isDevEnvironment()) {
    //   //todo : remove this condition when landing page content is ready
    //   this.MenuSidebar = this.MenuSidebar.filter(
    //     (item) => item.title !== 'configuration',
    //   );
    // }
    const toggleItem = {
      icon: 'sctr-icon-chevron-right',
      command: () => {
        this.isMenuShow = !this.isMenuShow;
      },
    };
    this.dialItems = _.cloneDeep(MenuSidebar);
    this.dialItems.unshift(toggleItem);
    this.dialItems.forEach((item) => {
      item.routerLink = [item.path];
      item.icon += ' before:!text-white';
    });
  }

  isActive(path: string, isParent?: boolean) {
    const locationPath = window.location.pathname;
    if (isParent) {
      return locationPath.slice(1) === path;
    } else {
      const index = locationPath.search(path);
      return (
        index > 0 &&
        locationPath[index - 1] === '/' &&
        locationPath.includes(path)
      );
    }
  }

  getMenuIcon() {
    return this.isMenuShow ? 'pi pi-chevron-left' : 'pi pi-chevron-right';
  }

  updateSideBar(value: boolean = this.sidebarVisible) {
    this.sidebarVisible = value;
    this.sidebarVisibleChange.emit(this.sidebarVisible);
  }

  onNavigate(path?: string) {
    if (!path) return;
    // this.router.navigateByUrl(path);
    return path;
  }

  getItemSidebar(item: NavigationItem) {
    if (item.children) {
      return '#';
    } else {
      return this.onNavigate(item.path);
    }
  }
  isDevEnvironment() {
    return (
      environment.state === 'DEVELOPMENT' ||
      environment.state === 'LOCAL' ||
      environment.state === 'STAGING'
    );
  }
}
