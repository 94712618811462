import { Component, Input } from '@angular/core';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  FileDTO,
  LPSectionDTO,
  LPSectionItemDTO,
} from '@assistant/angular-travel-admin-service';
import { DropdownChangeEvent, DropdownFilterEvent } from 'primeng/dropdown';
import { SearchingPlaceControllerService } from '@assistant/angular-map-location-service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { MessageService } from 'primeng/api';
import { CustomTranslateService } from '@core/services/custom-translate.service';
import { LPSectionAdminViewDTO } from '@assistant/angular-travel-admin-service/model/lPSectionAdminViewDTO';
@Component({
  selector: 'app-explore-places',
  templateUrl: './explore-places.component.html',
  styleUrls: ['./explore-places.component.scss'],
})
export class ExplorePlacesComponent {
  @Input() explorePlacesSection: LPSectionAdminViewDTO | undefined;
  prefix: string = 'landing-page-content.';
  places: LPSectionItemDTO[] | undefined = [];
  originalPlaces: LPSectionItemDTO[] | undefined = [];
  newItem: LPSectionItemDTO;
  uploading: boolean = false;

  constructor(
    private searchingPlaceService: SearchingPlaceControllerService,
    private httpClient: HttpClient,
    private messageService: MessageService,
    private translator: CustomTranslateService,
  ) {}
  ngOnChanges() {
    if (this.explorePlacesSection) {
      this.places = this.explorePlacesSection.items;
      this.originalPlaces = this.places;
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.explorePlacesSection?.items!,
      event.previousIndex,
      event.currentIndex,
    );
  }
  filterPlaces($event: DropdownFilterEvent) {
    this.searchingPlaceService
      .searchPlace($event.filter, undefined, 10, undefined, true, true)
      .subscribe((res) => {
        if (res.data) {
          const data = res.data.data;
          this.places = data?.map((item) => {
            const a: LPSectionItemDTO = {
              title: item.main_text,
              id: item.id,
            };
            return a;
          });

          this.places = this.places?.concat(this.originalPlaces!);
        }
      });
  }
  selectCurrentPlace(place: any) {
    const a = this.places?.find((item) => item.title === place.title);
    return a;
  }
  handleSelect($event: DropdownChangeEvent) {
    this.originalPlaces?.push($event.value);
  }
  removeExplore(i: number) {
    this.explorePlacesSection?.items?.splice(i, 1);
  }

  selectedFile: FileDTO | undefined;
  onFileSelected($event: any) {
    const formData = new FormData();
    formData.append('file', $event?.target?.files[0]);
    this.httpClient
      .post(`${environment.baseURL}/storage/files/upload-file`, formData)
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.selectedFile = {
              id: res.data.id,
              extension: res.data.extension,
              type: res.data.type,
              description: res.data.description,
              original_name: res.data.original_name,
            };
          } else {
            console.log(res.message);
          }
        },
        error: (err) => {},
      });
  }

  onFileReplace($event: any, explorePlace: number) {
    this.uploading = true;
    const formData = new FormData();
    formData.append('file', $event?.target?.files[0]);
    this.httpClient
      .post(`${environment.baseURL}/storage/files/upload-file`, formData)
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            const selectedFile = {
              id: res.data.id,
              extension: res.data.extension,
              type: res.data.type,
              description: res.data.description,
              original_name: res.data.original_name,
            };
            if (this.explorePlacesSection && this.explorePlacesSection.items)
              this.explorePlacesSection.items[explorePlace].image =
                selectedFile;
            // explorePlace.image = selectedFile;
          } else {
            console.log(res.message);
          }
        },
        error: (err) => {},
        complete: () => {
          this.uploading = false;
        },
      });
  }
  resetItem() {
    this.newItem = {};
    this.selectedFile = undefined;
  }
  addExplore() {
    if (!this.newItem || !this.selectedFile) {
      return;
    }
    const item: LPSectionItemDTO = {
      title: this.newItem.title,
      id: this.newItem.id,
      image: this.selectedFile,
      is_active: true,
    };
    this.messageService.add({
      severity: 'success',
      summary: this.translator.transform(this.prefix + 'success'),
      detail: this.translator.transform(
        this.prefix + 'new-explore-place-added-successfully',
      ),
    });
    if (this.explorePlacesSection && !this.explorePlacesSection?.items) {
      this.explorePlacesSection.items = [];
    }
    this.explorePlacesSection?.items?.push(item);
    this.originalPlaces = this.explorePlacesSection?.items;
    this.places = this.originalPlaces;
    this.newItem = {};
    this.selectedFile = undefined;
  }
  addPlace($event: any) {
    this.newItem = {
      title: $event.title,
      id: $event.id,
    };
    return this.newItem;
  }
  updateExplore($event: any, index: number) {
    if (
      this.explorePlacesSection?.items &&
      this.explorePlacesSection.items[index]
    ) {
      this.explorePlacesSection.items[index].title = $event.title;
      this.explorePlacesSection.items[index].id = $event.id;
    }
  }
}
