import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileDTO, LPSectionDTO } from '@assistant/angular-travel-admin-service';
import { environment } from '@env/environment';
import { LPSectionAdminViewDTO } from '@assistant/angular-travel-admin-service/model/lPSectionAdminViewDTO';

@Component({
  selector: 'app-blog-section',
  templateUrl: './blog-section.component.html',
  styleUrls: ['./blog-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlogSectionComponent {
  @Input() blogSection: LPSectionAdminViewDTO | undefined;
  heading = new FormControl();
  content = new FormControl();
  hide: boolean = false;
  images: any[] = [1, 2, 3];
  prefix: string = 'landing-page-content.';
  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1,
    },
  ];
  uploading = false;
  selectedFiles: FileDTO[] | undefined = [];
  listLangsHeading: string[] = [];
  activeLangHeading: string = '';
  listLangsContent: string[] = [];
  activeLangContent: string = '';

  constructor(
    private httpClient: HttpClient,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
  ) {}

  ngOnChanges() {
    if (this.blogSection) {
      this.listLangsHeading = Object.keys(this.blogSection.heading || {});
      this.activeLangHeading = this.listLangsHeading[0];
      this.heading.setValue(
        this.blogSection.heading
          ? this.blogSection.heading[this.activeLangHeading]
          : '',
      );

      this.listLangsContent = Object.keys(this.blogSection.content || {});
      this.activeLangContent = this.listLangsContent[0];
      this.content.setValue(
        this.blogSection.content
          ? this.blogSection.content[this.activeLangContent]
          : '',
      );
      this.selectedFiles = this.blogSection.items?.map((item) => {
        return item.image as FileDTO;
      });
    }
  }

  removeFile(selectFile: FileDTO) {
    this.selectedFiles = this.selectedFiles?.filter(
      (file) => file.id !== selectFile.id,
    );
    this.blogSection!.items = this.blogSection!.items?.filter(
      (item) => item?.image?.id !== selectFile.id,
    );
  }

  changeLangHeading(lang: any) {
    if (this.blogSection && this.blogSection.heading) {
      this.activeLangHeading = lang;
      this.heading.setValue(this.blogSection.heading[lang]);
    }
  }

  changeLangContent(lang: string) {
    if (this.blogSection && this.blogSection.content) {
      this.activeLangContent = lang;
      this.content.setValue(this.blogSection.content[lang]);
    }
  }

  onFileSelectedReplace($event: any, fileReplace: any) {
    const formData = new FormData();
    formData.append('file', $event?.target?.files[0]);
    this.uploading = true;

    this.httpClient
      .post(`${environment.baseURL}/storage/files/upload-file`, formData)
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            const uploadedFile = {
              id: res.data.id,
              extension: res.data.extension,
              type: res.data.type,
              description: res.data.description,
              original_name: res.data.original_name,
            };
            const index = this.selectedFiles?.findIndex(
              (file) => file.id === fileReplace.id,
            );
            if (index !== undefined && index !== -1) {
              this.selectedFiles![index] = uploadedFile;
              this.selectedFiles = [...this.selectedFiles!];
            }
            this.cdr.detectChanges();
            if (this.blogSection) {
              this.blogSection.items = this.selectedFiles?.map((file) => {
                return {
                  image: file,
                };
              });
            }
          } else {
            console.log(res.message);
          }
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.uploading = false;
        },
      });
  }

  onFileSelected($event: any) {
    const files = $event?.target?.files;
    const formData = new FormData();
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
    }
    this.uploading = true;
    this.httpClient
      .post(`${environment.baseURL}/storage/files/upload-files`, formData)
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            if (!this.selectedFiles) this.selectedFiles = [];
            this.selectedFiles = this.selectedFiles?.concat(
              res.data.map((file: any) => {
                return {
                  id: file.id,
                  extension: file.extension,
                  type: file.type,
                  description: file.description,
                  original_name: file.original_name,
                };
              }),
            );
            if (this.blogSection) {
              this.blogSection.items = this.selectedFiles?.map((file) => {
                return {
                  image: file,
                };
              });
            }
          } else {
            console.log(res.message);
          }
        },
        error: (err) => {
          console.error(err);
        },
        complete: () => {
          this.uploading = false;
        },
      });
  }

  inputHeading() {
    if (!this.blogSection) {
      return;
    }
    if (!this.blogSection.heading) {
      this.blogSection.heading = {};
    }
    this.blogSection.heading[this.activeLangHeading] = this.heading.value;
  }

  inputContent() {
    if (!this.blogSection) {
      return;
    }
    this.blogSection.content = this.blogSection.content ?? {};
    this.blogSection.content[this.activeLangContent] = this.content.value;
  }

  checkKeys(inputObject: { [key: string]: string }, keys: string[]): boolean {
    return keys.every((key) => key in inputObject && inputObject[key] !== '');
  }
}
