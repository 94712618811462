import { LandingPageContentModule } from './features/landing-page-content/landing-page-content.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { DropdownModule } from 'primeng/dropdown';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import {
  ApiModule as AngularCatalogApiModule,
  BASE_PATH as ANGULAR_CATALOG_PATH,
} from '@soctrip/angular-catalog-service';
import {
  ApiModule as PromotionApiModule,
  BASE_PATH as PROMOTION_PATH,
} from '@soctrip/angular-promotion-service';
import {
  ApiModule as AngularShopApiModule,
  BASE_PATH as ANGULAR_SHOP_PATH,
} from '@soctrip/angular-shop-service';

import {
  ApiModule as PaymentApiModule,
  BASE_PATH as PAYMENT_PATH,
} from '@soctrip/angular-payment-service';

import {
  ApiModule as StorageApiModule,
  BASE_PATH as STORAGE_PATH,
} from '@soctrip/angular-storage-service';
import {
  ApiModule as AdvertisingApiModule,
  BASE_PATH as ADVERTISING_PATH,
} from '@soctrip/angular-advertising-service';
import {
  ApiModule as TravelAdminApiModule,
  BASE_PATH as TRAVEL_ADMIN_PATH,
} from '@assistant/angular-travel-admin-service';
import {
  ApiModule as RegionalApiModule,
  BASE_PATH as REGIONAL_PATH,
} from '@soctrip/angular-regional-service';
import {
  ApiModule as MapLocationModule,
  BASE_PATH as MAP_LOCATION_PATH,
} from '@assistant/angular-map-location-service';
import { environment } from 'src/environments/environment';
import { Locales } from './core/constants/locales.config';
import { BookingHelpdeskDetailModule } from './features/booking-helpdesk-details/booking-helpdesk-detail.module';
import { AdminSupportModule } from './features/admin-support/admin-support.module';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { defineCustomElements } from '@soctrip-common/discount-selector/loader';

defineCustomElements(window);

const enum ServiceName {
  STOCK = 'STOCK',
  CATALOG = 'CATALOG',
  SHOP = 'SHOP',
  STORAGE = 'STORAGE',
  ORDER = 'ORDER',
  PROMOTION = 'PROMOTION',
  PAYMENT = 'PAYMENT',
  SHIPMENT = 'SHIPMENT',
  ADVERTISING = 'ADVERTISING',
  TRAVEL_ADMIN = 'TRAVEL_ADMIN',
  REGIONAL = 'REGIONAL',
  MAP_LOCATION = 'MAP_LOCATION',
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DropdownModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularCatalogApiModule,
    PromotionApiModule,
    AngularShopApiModule,
    PaymentApiModule,
    StorageApiModule,
    AdvertisingApiModule,
    TravelAdminApiModule,
    RegionalApiModule,
    BookingHelpdeskDetailModule,
    AdminSupportModule,
    ToastModule,
    LandingPageContentModule,
    MapLocationModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: ADVERTISING_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.ADVERTISING]
      }`,
    },
    {
      provide: ANGULAR_CATALOG_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.CATALOG]
      }`,
    },
    {
      provide: PROMOTION_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.PROMOTION]
      }`,
    },
    {
      provide: ANGULAR_SHOP_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.SHOP]
      }`,
    },
    {
      provide: PAYMENT_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.PAYMENT]
      }`,
    },
    {
      provide: STORAGE_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.STORAGE]
      }`,
    },
    {
      provide: TRAVEL_ADMIN_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.TRAVEL_ADMIN]
      }`,
    },
    {
      provide: REGIONAL_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.REGIONAL]
      }`,
    },
    {
      provide: MAP_LOCATION_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.MAP_LOCATION]
      }`,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    this.initI18n();
  }
  private initI18n() {
    this.translate.addLangs(Locales);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
